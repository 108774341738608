import clsx from 'clsx';
import React from 'react';
import styles from './Layout.module.scss';
import { LayoutProps, LayoutVariant } from './interfaces';

const Layout = React.forwardRef<HTMLDivElement, LayoutProps>(
  (
    {
      children,
      variant = LayoutVariant.Default,
      background = 'white',
      className,
      hasBorder,
      headerSlot,
    }: LayoutProps,
    ref
  ) => {
    const classes = [
      styles[variant],
      styles[background],
      {
        [styles.hasBorder]:
          typeof hasBorder === 'undefined'
            ? variant === LayoutVariant.Default && background === 'white'
            : hasBorder,
      },
      className,
    ];

    return (
      <>
        {headerSlot && (
          <div className={clsx(styles.headerWrapper, ...classes)}>
            {headerSlot}
          </div>
        )}
        <div
          data-layout-background={background}
          className={clsx(styles.layout, ...classes)}
          ref={ref}
        >
          {children}
        </div>
      </>
    );
  }
);

Layout.displayName = 'Layout';

export default Layout;
