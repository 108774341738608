import React from 'react';

export type LayoutBackground = 'white' | 'gradient';

export interface LayoutProps {
  variant?: LayoutVariant | 'default' | 'fullWidth';
  background?: LayoutBackground;
  className?: string;
  children?: React.ReactNode;
  headerSlot?: React.ReactNode;
  hasBorder?: boolean;
}
/**
 * @deprecated
 */
export enum LayoutVariant {
  Default = 'default',
  FullWidth = 'fullWidth',
}
